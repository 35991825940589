import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'planos';

const isAmil = (nomeOperadora) => (nomeOperadora.indexOf("AMIL") >= 0);

const getPlanosPorProposta = async ({
  publicoAlvo, entidade, uf, cidade, operadora, beneficiarios, cpfCnpjCorretora, administradora, dataVigencia, verificarPossuiOpcional, portabilidade,
}) => {
  try {
    const body = {
      publicoAlvo,
      uf,
      cidade,
      entidade,
      beneficiarios,
      dataVigencia: dataVigencia || undefined,
      operadora,
      verificarPossuiOpcional,
      portabilidade: typeof portabilidade === 'boolean' ? portabilidade : undefined,
    };
    const config = {
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PLANO_API_KEY }
    };
    const { data } = await api.post(`${resource}/lista`, { ...body, cpfCnpjCorretora, administradora }, config);

    // Caso uma AMIL seja selecionada todas devem retornar
    const list = data && data.data ? data.data.filter((f) => (
      (f.operadora === operadora) || (isAmil(f.operadora) && isAmil(operadora))
    )) : [];

    return list;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getPlano = async ({ input, administradora, verificarPossuiOpcional }) => {
  const config = {
    headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PLANO_API_KEY }
  };
  try {
    const { data } = await api.post(`${resource}/lista`, { ...input, administradora, verificarPossuiOpcional }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 500);
  }
};

const getPlanoSimulacao = async ({ input, administradora }) => {
  const config = {
    headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PLANO_API_KEY }
  };
  try {
    const { data } = await api.post(`${resource}/simulacao/lista`, { ...input, administradora }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 500);
  }
};

const getPlanoSimilar = async ({
  entidade, uf, cidade, operadora, beneficiarios, idProdutoFatura, dataVigencia, administradora, cpfCnpjCorretora, portabilidade,
}) => {
  try {

    const body = {
      uf,
      cidade,
      entidade,
      beneficiarios,
      idProdutoFatura,
      cpfCnpjCorretora,
      portabilidade: typeof portabilidade === 'boolean' ? portabilidade : undefined,
    };

    if (dataVigencia) body.dataVigencia = dataVigencia;
    const config = {
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PLANO_API_KEY }
    };
    const { data } = await api.post(`${resource}/lista`, { ...body, administradora }, config);
    const list = data && data.data ? data.data.filter((f) => f.operadora === operadora) : [];
    return list;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getProdutosOpcionais = async ({
  idProdutoFatura, body,
}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PLANO_API_KEY } };
    const { data } = await api.post(`${resource}/${idProdutoFatura}/opcionais/lista`, body, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getSegmentacoes = async () => {
  try {
    const config = {
      params: {
        cleanData: true,
      },
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PLANO_API_KEY }
    };
    const { data } = await api.get(`${resource}/segmentacoes`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  getPlanosPorProposta,
  getPlano,
  getPlanoSimulacao,
  getPlanoSimilar,
  getProdutosOpcionais,
  getSegmentacoes,
};
