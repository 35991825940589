<template>
  <div>
    <v-container class="mt-5">
      <v-row justify="center">
        <v-col class="col-12 col-md-6">
          <h3 class="font-weight-bold">
            Para salvar sua simulação, preencha os dados:
          </h3>
        </v-col>
      </v-row>
      <validation-observer tag="form" ref="form" class="mb-10" @submit.prevent="submit" autocomplete="off">
        <v-row justify="center" class="mt-5">
          <v-col class="col-12 col-md-6">
            <base-text-field
              mode="aggressive"
              rules="required|special_characters"
              id="nome"
              name="Nome completo"
              v-model="form.nome"
              label="Nome completo *"
              clearable
              outlined
              data-test-id="nome-completo"
            />
            <base-text-field type="email" rules="required|email" id="email" name="E-mail" v-model="form.email" label="E-mail *" clearable outlined data-test-id="e-mail" />
            <v-row>
              <v-col>
                <base-wrapper-dialog-options
                  headerTitle="Tipo telefone"
                  :options="[
                    { id: 'CELULAR', nome: 'CELULAR' },
                    { id: 'RESIDENCIAL', nome: 'RESIDENCIAL' },
                    { id: 'COMERCIAL', nome: 'COMERCIAL' },
                  ]"
                  @select="$set(form, 'tipoTelefone', $event.id)"
                  value-key="id"
                  label-key="nome"
                  ref="dialogtipoTelefone"
                >
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      readonly
                      v-on="on"
                      id="tipoTelefone"
                      name="Tipo telefone"
                      rules="required"
                      :value="form.tipoTelefone"
                      placeholder="Clique aqui para selecionar"
                      label="Tipo telefone *"
                      outlined
                      append-icon="mdi-chevron-right"
                      data-test-id="tipo-telefone"
                    />
                  </template>
                </base-wrapper-dialog-options>
              </v-col>
              <v-col>
                <base-text-field
                  type="tel"
                  :rules="'required|ddd' + (!loading && form.tipoTelefone == 'CELULAR' ? '|cellphone' : '')"
                  id="telefoneCelular"
                  name="Telefone"
                  v-model="form.telefone"
                  label="Telefone *"
                  v-mask="['## #####-####', '## ####-####']"
                  clearable
                  outlined
                  persistent-hint
                  data-test-id="telefone"
                />
              </v-col>
            </v-row>
            <base-text-field id="anotacao" name="anotacoes" v-model="form.anotacao" label="Anotações" clearable outlined data-test-id="anotações" />
            <v-row no-gutters class="mt-3">
              <v-col class="text-left">
                <v-btn color="primary" outlined data-test-id="voltar" @click="$emit('back')">
                  VOLTAR
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn type="submit" elevation="10" color="primary" class="secundaryBtn--text" :loading="loading" data-test-id="salvar">
                  SALVAR
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </validation-observer>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import filters from "@/filters";
import simulacaoService from "@/services/simulacaoService";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";

export default {
  name: "Registro",
  components: {
    BaseTextField,
    BaseWrapperDialogOptions,
  },
  data() {
    return {
      form: {},
      loading: false,
      mounting: true,
    };
  },
  computed: {
    ...mapState({
      dadosAdministradora: (state) => state.simulacao.administradora,
      dadosGuid: (state) => state.simulacao.guid,
      dadosTitular: (state) => state.simulacao.titular,
      dadosEndereco: (state) => state.simulacao.endereco,
      dadosDependentes: (state) => state.simulacao.dependentes,
      dadosPlano: (state) => state.simulacao.plano,
      dadosAnotacao: (state) => state.simulacao.anotacao,
    }),
  },
  watch: {
    dadosTitular: {
      immediate: true,
      handler: "setDataTitular",
      deep: true,
    },

    dadosAnotacao: {
      immediate: true,
      handler: "setAnotacao",
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setTitular: "simulacao/setTitular",
      setAnotacao: "simulacao/setAnotacao",
    }),
    setDataTitular() {
      if (!this.mounting) return;
      this.mounting = false;
      const titular = this.$cleanObserver(this.dadosTitular);
      this.form = this.$cleanObserver(titular);
      if (titular.telefone && titular.telefone[0]) {
        this.form.telefone = titular.telefone[0].numero;
        this.form.tipoTelefone = titular.telefone[0].tipo;
      }
    },
    setAnotacao() {
      this.form.anotacao = this.$cleanObserver(this.dadosAnotacao);
    },
    setAdministradora(Administradora) {
      let administradoraPlano;
      if (Administradora && ["QUALICORP ADMINISTRADORA", "QUALICORP"].includes(Administradora)) {
        administradoraPlano = "qualicorp";
      }

      if (Administradora && ["Uniconsult"].includes(Administradora)) {
        administradoraPlano = "uniconsult";
      }

      if (Administradora && ["CEMIG SAUDE"].includes(Administradora)) {
        administradoraPlano = "cemig";
      }

      if (Administradora && ["Plural Administradora"].includes(Administradora)) {
        administradoraPlano = "plural";
      }

      if (Administradora && ["Elo"].includes(Administradora)) {
        administradoraPlano = "elo";
      }

      if (Administradora && ["Aliança"].includes(Administradora)) {
        administradoraPlano = "alianca";
      }

      return administradoraPlano || Administradora;
    },
    /* eslint-disable */
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.loading = true;

      const form = { ...this.form };
      const { email, nome, anotacao, tipoTelefone, telefone } = this.$cleanObserver(form);

      const titular = {
        ...this.dadosTitular,
        email,
        nome,
        anotacao,
        telefone: {
          tipo: tipoTelefone,
          numero: filters.removeSymbols(telefone),
          preferencial: tipoTelefone === "CELULAR",
        },
      };

      this.setTitular({ titular });
      this.setAnotacao({ anotacao });

      try {
        const dados = {
          guid: this.dadosGuid,
          titular,
          endereco: this.dadosEndereco,
          plano: this.dadosPlano,
          dependentes: this.dadosDependentes,
          contratado: false,
          anotacao,
          administradora: this.setAdministradora(this.dadosPlano[0].administradora || "QUALICORP ADMINISTRADORA"),
        };
        await simulacaoService.add({ dados });
        this.$router.push({ name: "areaLogada.simulacoes" });
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    /* eslint-enable */
  },
};
</script>

<style></style>
